/**=====================
  11. Button CSS start
==========================**/
@mixin btn-gradien($deg,$leftcolor,$leftcolor-per,$rightcolor,$rightcolor-per,$font-color){
  background-image: linear-gradient(to right,$leftcolor 0%,$rightcolor 51%,$leftcolor 100%);
  border:none;
  color:$font-color;
  background-size: auto 200%;
  transition: all 0.3s ease;
}
@mixin btn-squre($border-radius){
  border-radius:$border-radius+px;
}
@mixin btn-pill($border-radius){
  border-radius:$border-radius+px;
}
@mixin btn-pill-first($radius){
  border-radius: $radius+px 0 0 $radius+px;
}
@mixin btn-pill-last($radius){
  border-radius: 0 $radius+px $radius+px 0;
}
.btn{
  font-size: $btn-font-size;
  padding: $btn-padding;
  font-weight:600;
}
.btn-lg{
  font-size: $btn-lg-font-size;
}
.btn-sm{
  font-size: $btn-sm-font-size;
}
.btn-xs {
  padding: $btn-xs-padding;
  font-size: $btn-xs-font-size;
}
.large-btn{
  .btn{
    margin-right: 5px;
    margin-bottom: 15px;
  }
  margin-bottom: -20px;
}
// outline buttons//
@each $btn-name, $btn-color in (primary, $primary-color),
        (secondary, $secondary-color) ,
        (success, $success-color),
        (danger, $danger-color),
        (info, $light-font),
        (light, $light-gray),
        (dark, $dark-color),
        (warning, $warning-color) {
  .btn-air-#{$btn-name}{
    box-shadow: 0 5px 10px 2px rgba($primary-color,0.19) !important;
    &:hover,&:active,&:not([disabled]):not(.disabled):active{
      background-color: darken( $btn-color, 10% );
      border-color: darken( $btn-color, 10% );
    }
  }
}
.btn-info{
  color: $white;
}
// forms //
@each $btn-name, $btn-color in (primary, $primary-color),
        (secondary, $secondary-color) ,
        (success, $success-color),
        (danger, $danger-color),
        (info, $light-font),
        (light, $light-gray),
        (dark, $dark-color),
        (warning, $warning-color) {
  .input-air-#{$btn-name}{
    box-shadow: 0 3px 5px 1px rgba(88,103,221,.15)!important;
    &:focus{
      border-color:$btn-color;
    }
  }
}
//input background //
@each $btn-name, $btn-color in (primary, $primary-color),
        (secondary, $secondary-color) ,
        (success, $success-color),
        (danger, $danger-color),
        (info, $light-font),
        (light, $light-gray),
        (dark, $dark-color),
        (warning, $warning-color) {
  .form-bg-#{$btn-name}{
    background:$btn-color;
    border-color:$btn-color;
    @if($btn-name == light){
      color:$dark-color;
    }
    @else{
      color: $white;
    }
    &:focus{
      border-color:$btn-color;
      background:$btn-color;
      @if($btn-name == light){
        color:$dark-color;
      }
      @else{
        color: $white;
      }
      &:active{
        border-color:$btn-color;
        background:$btn-color;
        @if($btn-name == light){
          color:$dark-color;
        }
        @else{
          color: $white;
        }
      }
    }
  }
}
.btn-warning{
  color: $white;
  &:hover,
  &.disabled{
    color: $white;
  }
}
.btn-primary-gradien{
  @include btn-gradien(60,lighten($primary-color,8%),0%,darken($primary-color,8%),100%,$white);  
  &:hover {
    color: $theme-body-font-color !important;
  }
  &:focus,&:active,&.active{
      transition: 1.5s;
      background-position: right center;
      background-image: linear-gradient(to right, lighten($primary-color,8%),0%,darken($primary-color,8%),100%,$white) !important;

  }
}
.btn-secondary-gradien{
  @include btn-gradien(60,lighten($secondary-color,8%),0%,darken($secondary-color,8%),100%,$white);
 &:hover,&:focus,&:active,&.active,&.hover{
    transition: 1.5s;
    background-position: right center;
   background-image: linear-gradient(to right, lighten($secondary-color,8%),0%,darken($secondary-color,8%),100%,$white) !important;
  }
}
.btn-success-gradien{
  @include btn-gradien(60,lighten($success-color,8%),0%,darken($success-color,8%),100%,$white);
 &:hover,&:focus,&:active,&.active,&.hover{
    transition: 1.5s;
    background-position: right center;
   background-image: linear-gradient(to right, lighten($success-color,8%),0%,darken($success-color,8%),100%,$white) !important;
  }
}
.btn-danger-gradien{
  @include btn-gradien(60,lighten($danger-color,8%),0%,darken($danger-color,8%),100%,$white);
 &:hover,&:focus,&:active,&.active,&.hover{
    transition: 1.5s;
    background-position: right center;
   background-image: linear-gradient(to right, lighten($danger-color,8%),0%,darken($danger-color,8%),100%,$white) !important;
  }
}
.btn-warning-gradien{
  @include btn-gradien(60,lighten($warning-color,8%),0%,darken($warning-color,8%),100%,$white);
 &:hover,&:focus,&:active,&.active,&.hover{
    transition: 1.5s;
    background-position: right center;
   background-image: linear-gradient(to right, lighten($warning-color,8%),0%,darken($warning-color,8%),100%,$white) !important;
  }
}
.btn-info-gradien{
  @include btn-gradien(60,lighten($light-font,8%),0%,darken($light-font,8%),100%,$white);
 &:hover,&:focus,&:active,&.active,&.hover{
    transition: 1.5s;
    background-position: right center;
   background-image: linear-gradient(to right, lighten($light-font,8%),0%,darken($light-font,8%),100%,$white) !important;
  }
}
.btn-light-gradien{
  @include btn-gradien(60,lighten($light-gray,8%),0%,darken($light-gray,8%),100%,$white);
 &:hover,&:focus,&:active,&.active,&.hover{
    transition: 1.5s;
    background-position: right center;
   background-image: linear-gradient(to right, lighten($light-gray,8%),0%,darken($light-gray,8%),100%,$white) !important;
  }
}
.btn-dark-gradien{
  @include btn-gradien(60,lighten($dark-color,8%),0%,darken($dark-color,8%),100%,$white);
 &:hover,&:focus,&:active,&.active,&.hover{
    transition: 1.5s;
    background-position: right center;
   background-image: linear-gradient(to right, lighten($dark-color,8%),0%,darken($dark-color,8%),100%,$white) !important;
  }
}
[class*='-gradien']{
  &:hover{
    background-size: 50% 100%;
    transition: all 0.3s ease;
    color: $white;
  }
}
.btn-square{
  @include btn-squre(0);
}
.btn-pill{
  @include btn-squre(60);
}
.btn-group-pill {
  .btn{
    &:first-child{
      @include btn-pill-first(60);
    }
    &:last-child{
      @include btn-pill-last(60);
    }
  }
}
.btn-group-square{
  .btn{
    &:first-child{
      @include btn-pill-first(0);
    }
    &:last-child{
      @include btn-pill-last(0);
    }
  }
}
.btn-showcase{
  .btn{
    margin-bottom: 10px;
    margin-right: 18px;
  }
  margin-bottom:-10px;
}
.btn-dropdown-showcase{
  .dropdown{
    margin-bottom: 10px;
    margin-right: 18px;
  }
  .btn-group{
    margin-bottom: 10px;
    margin-right: 18px;
  }
  margin-bottom:-10px;
}
.btn-group{
  .radio{
    label{
      &::before{
        top: 2px;
      }
      &::after{
        top: 7px;
      }
    }
  }
  .checkbox{
    label{
      margin-top: 3px;
    }
  }
}
.btn-group-showcase{
  >div{
    margin-bottom: -20px;
    label{
      margin-bottom: 0;
    }
  }
  .btn-group{
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .checkbox {
    input[type="checkbox"] {
      display: none;
    }
  }
}
.tooltiptext {
  visibility: visible;
  width: 120px;
  background-color: #555;
  color: $white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 1;
  transition: opacity 0.3s;
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left:-5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
}
.topright-general{
  width:20px;
  height:20px;
  border-radius:50%;
  display:inline-grid;
  align-items:center;
  justify-content:center; 
  &:before{
    transform:rotate(45deg);
  }
}
.toprightarrow-primary{
  color:$primary-color;
  background-color: rgba($primary-color,0.1); 
  @extend .topright-general ;
}
.toprightarrow-secondary{
  color:$secondary-color;
  background-color: rgba($secondary-color,0.1);
  @extend .topright-general ;
}
.btn-arrow{
  font-size:12px;
  font-weight:700;  
  display:flex;
  align-items:center;
  margin-left:auto;
  margin-right:auto;
  width: fit-content;
  margin-left: auto;
  margin-right:auto; 
  &.arrow-primary{
    color:$primary-color ;   
  } 
  &.arrow-secondary{
    color:$secondary-color ;
  }
}
.btn-iconsolid{
  border-radius:5px;
  background-color:$primary-color;
  padding:8px 12px;
  i{
    color:$white;
    font-weight:700;
  }
}
/**=====================
  11. Button CSS end
==========================**/