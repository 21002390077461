/**=====================
  31. Footer CSS Start
==========================**/
footer {
  background-color: $footer_bg_color;
  padding: 15px;
  bottom: 0;
  left: 0;
  margin-left: 290px;
  a {
    font-weight: 600;
  }
  p {
    font-size: 14px;
    font-weight: 500;
    color: $light-text;
  }
}
.page-wrapper {
  .page-body-wrapper {
    .footer-dark {
      background-color: $footer_dark_color;
      p {
        color: $white !important;
      }
      li {
        a {
          color: $footer_dark__txt_color;
        }
      }
    }
  }
}
.footer-fix {
  width: calc(100% - 290px);
  position: fixed;
}
.page-wrapper.horizontal-wrapper {
  .page-body-wrapper {
    footer {
      transition: all 0.3s ease;
      bottom: 0;
      z-index: 8;
      margin-left: 0;
      p {
        color: $theme-body-font-color;
        i {
          color: var(--theme-secondary);
          margin-left: 5px;
        }
      }
    }
  }
}
/**=====================
  31. Footer CSS Ends
==========================**/