/**=====================
  55. Popover CSS Start
==========================**/
.popover {
  border: 1px solid $light-gray;
  box-shadow: none;
}
.popover-header {
  background-color: rgba($primary-color, 0.1);
  border-color: $light-gray;
  color: var(--theme-default);
}
.popover-body {
  border-color: $light-gray;
  background-color: $white;
}
.popover-main {
  .btn-showcase {
    a {
      color: $white;
    }
    button {
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
/**=====================
  55. Popover CSS Ends
==========================**/