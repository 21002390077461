/**=====================
  51. NAV CSS Start
==========================**/
.nav-list {
  padding: 30px;
  .nav-list-disc {
    text-align: left;
    color: $dark-gray;
    li {
      padding: 9px 0;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
      a {
        color: $theme-body-font-color;
        .fa {
          width: 14px;
          font-size: 16px;
        }
      }
      &:hover {
        color: var(--theme-default);
        a {
          color: var(--theme-default);
        }
      }
    }
  }
}
.navs-icon {
  padding: 30px;
  svg {
    width: 21px;
    height: 21px;
    margin-right: 10px;
    vertical-align: middle;
  }
  .fa {
    font-size: 16px;
    margin-right: 10px;
  }
  li {
    padding: 9px 0;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
    .text-muted {
      color: $theme-body-font-color !important;
    }
    a {
      display: flex;
      align-items: center;
    }
    ul {
      padding-top: 9px;
      li {
        line-height: 1;
        &:first-child {
          padding-top: 9px;
        }
        &:hover {
          color: var(--theme-default);
          a {
            color: var(--theme-default);
          }
        }
      }
    }
    a {
      color: $theme-body-font-color;
      &:hover {
        color: var(--theme-default);
      }
      &.active {
        color: var(--theme-default);
      }
      span {
        font-family: $font-rubik, $font-serif;
      }
    }
    button {
      &:hover {
        a {
          color: $white;
        }
      }
    }
  }
  .main-section {
    padding-top: 30px;
  }
  .separator {
    border-bottom: 1px solid $light-gray;
    margin: 30px 0;
    padding: 0;
  }
  .btn-link {
    text-decoration: none;
    line-height: 1;
    padding: 0;
    padding-bottom: 6px;
    &:hover {
      text-decoration: none;
    }
  }
}
#accordionoc {
  #collapseicon,
  #collapseicon1 {
    padding-top: 0px;
  }
  li {
    button {
      display: flex;
      align-items: center;
    }
  }
}
.navs-icon.default-according.style-1 {
  li {
    button {
      position: relative;
      &:hover {
        color: var(--theme-default) !important;
      }
    }
    button[aria-expanded="true"] {
      &:before {
        right: 2px;
        top: 6px;
      }
    }
    button[aria-expanded="false"]:before {
      right: 2px;
      top: 6px;
    }
  }
}
.navs-dropdown {
  button {
    margin-top: 30px;
  }
  .onhover-show-div {
    width: 250px;
    border-radius: 4px;
    box-shadow: 0 0 2px 2px $light-gray;
    button {
      margin-top: 0px;
    }
    .navs-icon {
      li {
        text-align: left;
        a {
          &:hover,
          &.active {
            svg {
              path,
              line,
              polyline {
                color: var(--theme-default) !important;
              }
            }
          }
        }
      }
    }
  }
}
.onhover-dropdown.navs-dropdown:hover .onhover-show-div:before {
  right: 77px;
  left: unset;
}
.onhover-dropdown.navs-dropdown:hover .onhover-show-div:after {
  right: 77px;
  left: unset;
}
.icon-lists {
  font-family: $font-rubik, $font-serif;
}
.pl-navs-inline {
  padding-left: 30px !important;
}
.inline-nav {
  li {
    line-height: 1.3;
  }
}
.nav-tabs {
  border-color: $light-gray;
  .nav-link {
    &.active {
      background-color: rgba($primary-color, 0.1);
      border-color: $light-gray;
      color: var(--theme-default);
    }
  }
  .nav-item {
    &.show {
      .nav-link {
        background-color: rgba($primary-color, 0.1);
      }
    }
  }
}
/**=====================
  51. NAV CSS Ends
==========================**/