/**=====================
  82. Wishlist CSS start
==========================**/
.wishlist {
  table {
    tr {
      td {
        svg {
          color: $danger-color;
        }
      }
    }
  }
}
/**=====================
  82. Wishlist CSS Ends
==========================**/