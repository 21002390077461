/**=====================
  30. File Manager css start
==========================**/
.file-sidebar,
.file-content {
  .card {
    .card-body,
    .card-header {
      padding: 20px !important;
    }
    .file-manager {
      padding-top: unset !important;
    }
  }
}
.file-manager {
  .files,
  .folder {
    li {
      &:nth-child(n + 2) {
        margin-left: 10px;
      }
    }
  }
}
.file-content {
  .card-header {
    form {
      .form-control {
        border: unset;
      }
    }
  }
}
.file-sidebar {
  ul {
    li {
      & + li {
        margin-top: 8px;
      }
    }
  }
  .btn {
    display: flex;
    align-items: center;
    &.btn-light {
      &:hover {
        color: var(--theme-default) !important;
        svg {
          stroke: var(--theme-default);
        }
      }
    }
    svg {
      width: 15px;
      vertical-align: middle;
      margin-right: 8px;
    }
  }
  .pricing-plan {
    border: 1px solid $light-gray;
    border-radius: 5px;
    margin-top: 10px;
    padding: 15px;
    position: relative;
    overflow: hidden;
    h6 {
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 5px;
      color: $dark-gray;
    }
    h5 {
      font-weight: 600;
      color: $theme-body-font-color;
    }
    p {
      margin-bottom: 10px;
      color: $light-text;
    }
    .btn {
      display: inline-block;
    }
    .bg-img {
      position: absolute;
      top: 40px;
      opacity: 0.1;
      transform: rotate(-45deg);
      right: -40px;
    }
  }
  h6 {
    font-size: 14px;
  }
}
.file-manager {
  h5 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
    color: $theme-body-font-color;
  }
  > h6 {
    opacity: 0.6;
    font-weight: 400 !important;
    font-size: 15px;
    margin-bottom: 20px;
    color: $light-text;
  }
  .files {
    .file-box {
      $show-numbers: true;
      @if $show-numbers {
        @for $i from 1 through 15 {
          &:nth-child(#{$i}) {
            animation-fill-mode: both;
            animation: fadeIncustom 0.5s linear #{$i}00000ms;
          }
        }
      }
    }
    h6 {
      margin-top: 10px;
      margin-bottom: 0;
      text-transform: capitalize;
    }
  }
  p {
    opacity: 0.9;
    font-size: 12px;
    color: $light-text;
  }
}
.files,
.folder {
  h6 {
    font-size: 14px;
  }
}
.file-content {
  .ellips {
    position: absolute;
    top: 30px;
    right: 30px;
    opacity: 0.7;
  }
  .form-inline {
    border: 1px solid $light-gray;
    border-radius: 5px;
    padding: 0 20px;
    i {
      padding-right: 10px;
      color: $dark-gray;
      line-height: 3;
    }
    input {
      &::-webkit-input-placeholder {
        color: $dark-gray;
      }
      &:focus {
        outline: none !important;
      }
    }
  }
  .search-form {
    input {
      padding: 5px 10px 5px 70px;
      border-radius: 5px;
    }
    .form-group {
      &:before {
        left: 82px;
        top: 37px;
      }
      &:after {
        top: 39px;
        left: 53px;
      }
    }
  }
  .btn {
    svg {
      height: 16px;
      margin-right: 2px;
      vertical-align: middle;
    }
  }
  h4 {
    font-weight: 600;
  }
  .folder {
    .folder-box {
      border: 1px solid $light-gray;
      border-radius: 5px;
      padding: 15px;
      background-color: rgba($primary-color, 0.05);
      width: calc(25% - 15px);
      display: inline-block;
      $show-numbers: true;
      @if $show-numbers {
        @for $i from 1 through 15 {
          &:nth-child(#{$i}) {
            animation-fill-mode: both;
            animation: fadeIncustom 0.5s linear #{$i}00000ms;
          }
        }
      }
    }
  }
  .files {
    .file-box {
      border: 1px solid $light-gray;
      border-radius: 5px;
      padding: 15px;
      background-color: rgba($primary-color, 0.05);
      width: calc(25% - 15px);
      display: inline-block;
      position: relative;
      .file-top {
        height: 100px;
        background-color: #fff;
        border: 1px solid $light-gray;
        border-radius: 5px;
        font-size: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .file-content .folder .folder-box .media {
    display: block;
    text-align: center;
    .media-body {
      margin-left: 0 !important;
      margin-top: 5px;
    }
  }
}
@media screen and (max-width: 1366px) {
  .file-content {
    .files {
      margin-bottom: -10px;
      display: block !important;
      .file-box {
        width: calc(50% - 15px);
        margin-bottom: 10px;
        margin-right: 8px;
        margin-left: unset !important;
      }
    }
    .card-header {
      .btn {
        padding: 8px 15px;
      }
    }
    .folder {
      display: block !important;
      .folder-box {
        padding: 13px;
        width: calc(50% - 15px);
        margin-bottom: 10px;
        margin-left: 5px !important;
        .media {
          i {
            font-size: 30px;
          }
        }
      }
    }
  }
  .file-sidebar {
    .btn {
      padding: 8px 15px;
    }
  }
}
@media screen and (max-width: 768px) {
  .file-content {
    .folder {
      margin-bottom: -10px;
      .folder-box {
        width: calc(50% - 15px);
        margin-bottom: 10px;
        margin-right: 8px;
        margin-left: unset !important;
      }
    }
    .media {
      display: block;
      text-align: center;
      .media-body {
        margin-top: 10px;
        text-align: center !important;
      }
    }
  }
}
@media screen and (max-width: 420px) {
  .file-content .folder .folder-box,
  .file-content .files .file-box {
    width: calc(100%);
    margin-right: unset;
  }
  .file-content {
    h4 {
      font-size: 20px;
    }
    .card-header {
      .btn {
        padding: 7px 10px;
        font-size: 12px;
      }
    }
  }
  .file-manager {
    > h6 {
      font-size: 14px;
    }
  }
}
/**=====================
  30. File Manager css end
==========================**/